/**
 * menu ATbusiness
 */
const routerATbusiness = [
  {
    path: '/atbusiness/dashboard',
    meta: { title: 'Dashboard' },
    component: () => import('./views/atbusiness/dashboard.vue'),
  },
  {
    path: '/atbusiness/customer-curah',
    meta: { title: 'Master Data Customer Curah' },
    component: () => import('./views/atbusiness/customer-curah'),
  },
  {
    path: '/atbusiness/group-customer',
    meta: { title: 'Master Data Grup Pelanggan' },
    component: () => import('./views/atbusiness/group-customer'),
  },
  {
    path: '/atbusiness/unggah-master-customer-curah',
    meta: { title: 'Unggah Master Customer Curah' },
    component: () => import ('./views/atbusiness/unggah-master-customer-curah'),
  },
  {
    path: '/atbusiness/rkap',
    meta: { title: 'Master Data RKAP' },
    component: () => import('./views/atbusiness/rkap'),
  },
  {
    path: '/atbusiness/penjualan-distributor-curah',
    meta: { title: 'Laporan Penjualan Distributor Curah' },
    component: () => import('./views/atbusiness/penjualan-distributor-curah'),
  },
  {
    path: '/atbusiness/sow-distributor-curah',
    meta: { title: 'Laporan SOW Distributor Curah' },
    component: () => import('./views/atbusiness/sow-distributor-curah'),
  },
  {
    path: '/atbusiness/pembayaran-customer-curah',
    meta: { title: 'Laporan Pembayaran Customer Curah' },
    component: () => import('./views/atbusiness/pembayaran-customer-curah.vue'),
  },
  {
    path: '/atbusiness/distributor-curah',
    meta: { title: 'Master Data Distributor Curah' },
    component: () => import('./views/atbusiness/distributor-curah'),
  },
  {
    path: '/atbusiness/log-api',
    meta: { title: 'Log API' },
    component: () => import('./views/atbusiness/log-api'),
  },
  {
    path: '/atbusiness/gpm-distributor',
    meta: { title: 'Laporan GPM Per Distributor' },
    component: () => import('./views/atbusiness/gpm-distributor.vue'),
  },
  {
    path: '/atbusiness/gpm-kelompok-pelanggan',
    meta: { title: 'Laporan GPM Per Kelompok Pelanggan' },
    component: () => import('./views/atbusiness/gpm-kelompok-pelanggan.vue'),
  },
  {
    path: '/atbusiness/ship-customer',
    meta: { title: 'Laporan Ship to Customer' },
    component: () => import('./views/atbusiness/ship-customer.vue'),
  },
  {
    path: '/atbusiness/upload-kam-customer',
    meta: { title: 'Unggah Mapping KAM per Customer' },
    component: () => import('./views/atbusiness/upload-kam-customer.vue'),
  },
  {
    path: '/atbusiness/upload-ship-customer',
    meta: { title: 'Unggah Mapping Shipto per Customer' },
    component: () => import('./views/atbusiness/upload-ship-customer.vue'),
  },
  {
    path: '/atbusiness/log-api',
    meta: { title: 'Log API' },
    component: () => import('./views/atbusiness/log-api'),
  },
  {
    path: '/atbusiness/reports/po-customer',
    meta: { title: 'Laporan PO Pelanggan' },
    component: () => import('./views/atbusiness/reports/po-customer.vue'),
  },
  {
    path: '/atbusiness/reports/finance',
    meta: { title: 'Laporan Keuangan' },
    component: () => import('./views/atbusiness/reports/finance.vue'),
  },
]

/**
 * menu toko
 */
const routerToko = [
  {
    path: '/laporan-toko/hapus-suggestion-toko-lelang',
    meta: { title: 'Laporan Delete Suggestion Toko Lelang' },
    component: () => import('./views/laporan-toko/delete-suggestion-toko-lelang'),
  },
]

/**
 * menu Helpdesk
 */
const routerHelpdesk = [
  {
    path: '/helpdesk/data-sales-person',
    meta: { title: 'Data Sales Person' },
    component: () => import('./views/helpdesk/data-sales-person.vue'),
  },
  {
    path: '/master/target-penjualan-distributor/:id/target-toko-spc',
    name: 'master.targetspc.detail',
    meta: { title: 'Master Data ・ Target Penjualan Distributor ・ Detail Target By SPC' },
    component: () => import('./views/master/target-penjualan-distributor/DetailTargetSpcList.vue'),
  },
  {
    path: '/master/target-penjualan-distributor/:id/target-toko-distributor',
    name: 'master.targetdistributor.detail',
    meta: { title: 'Master Data ・ Target Penjualan Distributor ・ Detail Target Pertoko Distributor' },
    component: () => import('./views/master/target-penjualan-distributor/DetailTargetDistributorList.vue'),
  },
  {
    path: '/master/cluster',
    redirect: '/master/cluster/master',
    component: () => import('./views/master/cluster'),
    children: [
      {
        path: '/master/cluster/master',
        meta: { title: 'Master Cluster' },
        component: () => import('./views/master/cluster/master/index.vue'),
      },
      {
        path: '/master/cluster/configuration',
        meta: { title: 'Konfigurasi Cluster' },
        component: () => import('./views/master/cluster/configuration'),
      },
      {
        path: '/master/cluster/periode',
        meta: { title: 'Periode Cluster' },
        component: () => import('./views/master/cluster/periode'),
      },
    ],
  },
]

/**
 * menu ATsalesman
 */
const routerATsalesman = [
  {
    path: '/atsalesman/jadwal-kunjungan-salesman',
    meta: { title: 'Jadwal Kunjungan Salesman' },
    component: () => import('./views/atsalesman/jadwal-kunjungan-salesman'),
  },
  {
    path: '/atsalesman/master-user-salesman',
    meta: { title: 'Master User Salesman' },
    component: () => import('./views/atsalesman/master-user-salesman'),
  },
  {
    path: '/atsalesman/hasil-survey',
    meta: { title: 'Laporan Hasil Survey' },
    component: () => import('./views/atsalesman/hasil-survey'),
  },
  {
    path: '/atsalesman/hasil-survey/:id',
    meta: { title: 'ATSalesman ・ Laporan Hasil Survey ・ Detail Hasil Survey' },
    component: () => import('./views/atsalesman/hasil-survey/detail.vue'),
  },
  {
    path: '/atsalesman/master-target-salesman',
    meta: { title: 'Master Data Target Salesman' },
    component: () => import('./views/atsalesman/master-target-salesman'),
  },
  {
    path: '/atsalesman/performance-monitoring-board',
    meta: { title: 'Performance Monitoring Board' },
    component: () => import('./views/atsalesman/performance-monitoring-board'),
  },
  {
    path: '/atsalesman/dashboard-visit-performance-completeness-survey',
    meta: { title: 'Dashboard Visit Performance & Completeness Survey' },
    component: () => import('./views/atsalesman/dashboard-visit-performance-completeness-survey'),
  },
  {
    path: '/atsalesman/summary/survey',
    meta: { title: 'Summary Survey' },
    component: () => import('./views/atsalesman/summary/survey.vue'),
  },
  {
    path: '/atsalesman/summary/rbp',
    meta: { title: 'Summary RBP' },
    component: () => import('./views/atsalesman/summary/rbp.vue'),
  },
  {
    path: '/atsalesman/summary/promotion',
    meta: { title: 'Summary Promo' },
    component: () => import('./views/atsalesman/summary/promotion.vue'),
  },
  {
    path: '/atsalesman/monitoring-price',
    meta: { title: 'Monitoring Harga' },
    component: () => import('./views/atsalesman/monitoring-price.vue'),
  },
]

/**
 * menu master tier
 */
const routerMasterTier = [
  {
    path: '/master-tier/appreciation-tiers',
    meta: { title: 'Appreciation Tiers' },
    component: () => import('./views/master-tier/appreciation-tiers'),
  },
  {
    path: '/master-tier/appreciation-benefits',
    meta: { title: 'Master Benefit' },
    component: () => import('./views/master-tier/appreciation-benefits'),
  },
  {
    path: '/master-tier/appreciation-rules',
    meta: { title: 'Appreciation Rule' },
    component: () => import('./views/master-tier/appreciation-rules'),
  },
]

/**
 * menu tim io
 */
const routerTimIO = [
  {
    path: '/tim-io/simulasi-level-stok',
    meta: { title: 'Simulasi Level Stok' },
    component: () => import('./views/laporan-tim-io/simulasi-level-stok'),
  },
  {
    path: '/tim-io/transaksi-e-pod',
    meta: { title: 'Laporan Transaksi E-Pod' },
    component: () => import('./views/laporan-tim-io/transaksi-epod'),
  },
]

/**
 * menu master
 */
const routerMaster = [
  {
    path: '/master/data-mapping-toko',
    meta: { title: 'Data Mapping Toko (V2)' },
    component: () => import('./views/master/data-mapping-toko'),
  },
  {
    path: '/master/master-data-rmx',
    meta: { title: 'Master Data RMX' },
    component: () => import('./views/master/master-rmx'),
  },
  {
    path: '/master/data-spsi',
    meta: { title: 'Master Data SPSI' },
    component: () => import('./views/master/spsi'),
  },
  {
    path: '/master/target-penjualan-distributor',
    meta: { title: 'Master Data Target Penjualan Distributor' },
    component: () => import('./views/master/target-penjualan-distributor'),
  },
]

/**
 * menu Penjualan
 */
const routerPenjualan = [
  {
    path: '/penjualan/toko-aktif-ca-lt',
    meta: { title: 'Laporan Toko Aktif (CA) - LT' },
    component: () => import('./views/penjualan/toko-aktif-ca-lt.vue'),
  },
  {
    path: '/penjualan/toko-aktif-ca',
    meta: { title: 'Laporan Toko Aktif (CA) V2' },
    component: () => import('./views/penjualan/toko-aktif-ca'),
  },
  {
    path: '/helpdesk/mapping-wilayah-sales-person',
    meta: { title: 'Laporan Mapping Wilayah Sales Person' },
    component: () => import('./views/helpdesk/mapping-wilayah-sales-person.vue'),
  },
]

export {
  routerToko,
  routerHelpdesk,
  routerATbusiness,
  routerATsalesman,
  routerMasterTier,
  routerTimIO,
  routerMaster,
  routerPenjualan,
}