import router from '@/router'
import { notification } from 'ant-design-vue'
import store from 'store'

import * as jwt from '@/services/jwt'
import * as user from '@/services/user'
// import { generateMetabaseMenu } from './metabaseRuntime'
import toArray from 'lodash/toArray'

import useCrypto from '@/composables/use-crypto'
const { encrypt } = useCrypto()

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    roles: [],
    regions: [],
    areas: [],
    actions: [],
    email: '',
    avatar: '',
    ip: '',
    last_login: '',
    vendor_id: null,
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
    phone: null,
    menu: [
      {
        children: [],
        url: '',
      },
    ],
    links: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const login = jwt.login
      return login(email, password)
        .then(response => {
          if (response) {
            const {
              areas,
              actions,
              email,
              id,
              ip,
              name,
              last_login,
              phone,
              regions,
              roles,
              vendor_id,
            } = response
            commit('SET_STATE', {
              id,
              name,
              email,
              roles,
              regions,
              areas,
              ip,
              last_login,
              vendor_id,
              actions,
              phone,
              authorized: true,
              loading: false,
            })

            store.set('profile', {
              areas,
              actions,
              email,
              id,
              ip,
              name,
              last_login,
              phone,
              regions,
              roles,
              vendor_id,
            })
            // notification.success({
            //   message: 'Logged In',
            //   description: 'You have successfully logged in!',
            // })
            // dispatch('LOAD_CURRENT_ACCOUNT', '_login_')
            window.location.href = '/'
          }
          if (!response) {
            commit('SET_STATE', {
              loading: false,
            })
          }
        })
        .catch(err => {
          commit('SET_STATE', {
            loading: false,
          })
          console.log('vuex login error ', err)
          return Promise.reject(err)
        })
    },
    AUTH_PROVIDER({ commit }, { payload }) {
      commit('SET_STATE', {
        loading: true,
      })

      return jwt.loginAuth(payload)
        .then(response => {
          if (response) {
            const {
              areas,
              actions,
              email,
              id,
              ip,
              name,
              last_login,
              phone,
              regions,
              roles,
              vendor_id,
            } = response
            commit('SET_STATE', {
              id,
              name,
              email,
              roles,
              regions,
              areas,
              ip,
              last_login,
              vendor_id,
              actions,
              phone,
              authorized: true,
              loading: false,
            })

            store.set('profile', {
              areas,
              actions,
              email,
              id,
              ip,
              name,
              last_login,
              phone,
              regions,
              roles,
              vendor_id,
            })
            // notification.success({
            //   message: 'Logged In',
            //   description: 'You have successfully logged in!',
            // })
            // dispatch('LOAD_CURRENT_ACCOUNT', '_login_')
            // router.push('/')
            window.location.href = '/'
          }
          if (!response) {
            commit('SET_STATE', {
              loading: false,
            })
          }
        })
        .catch(err => {
          commit('SET_STATE', {
            loading: false,
          })
          console.log('vuex login error ', err)
          return Promise.reject(err)
        })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = jwt.register
      register(email, password, name).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT', '_register_')
          // notification.success({
          //   message: 'Succesful Registered',
          //   description: 'You have successfully registered!',
          // })
          router.push('/')
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },

    // Inti refresh load
    LOAD_CURRENT_ACCOUNT({ commit, dispatch, state }, path) {
      return new Promise(function(resolve, reject) {
        commit('SET_STATE', {
          loading: true,
        })

        const currentAccount = jwt.currentAccount
        currentAccount(path)
          .then(response => {
            if (response) {
              jwt.userMenu().then(response => {
                if (response) {
                  const rename = value => {
                    if (!value || typeof value !== 'object') return value
                    if (Array.isArray(value)) return value.map(rename)
                    return Object.fromEntries(
                      Object.entries(value).map(([k, v]) => [
                        keys[k] || k,
                        k === 'id' ? v + '' : rename(v),
                      ]),
                    )
                  }
                  const keys = { href: 'url', id: 'key', name: 'title' }
                  let menus = rename(response)

                  menus.filter(m => !m.icon).forEach(m => (m.icon = 'fe fe-bookmark'))

                  let links = toArray(menus).flatMap((item) => { 
                    return item.children ? item.children.map(({ url }) => url) : item.url
                  })

                  commit('SET_STATE', {
                    menu: menus,
                    links: links,
                  })

                  resolve({
                    status: 'ok',
                    links: links,
                  })

                }
              })
            }
            commit('SET_STATE', {
              loading: false,
            })
          })
          .catch(err => {
            console.log('LOAD_CURRENT_ACCOUNT', err)
            if (err.response.status === 401) {
              commit('SET_STATE', {
                id: '',
                name: '',
                roles: [],
                regions: [],
                areas: [],
                actions: [],
                email: '',
                avatar: '',
                authorized: false,
                loading: false,
              })
            }
            reject(new Error(err))
          })
      })
    },
    // Inti refresh load

    LOGOUT({ commit, rootState }) {
      const logout = jwt.logout
      logout().then(() => {
        commit('SET_STATE', {
          id: '',
          name: '',
          roles: [],
          regions: [],
          areas: [],
          actions: [],
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
        })
        store.remove('profile')
        router.push('/#/guest/welcome')
      })
    },

    // Masking User Loader
    MASKING_USER({ commit }, data) {
      const {
          access_token,
          areas,
          actions,
          email,
          id,
          ip,
          name,
          last_login,
          phone,
          regions,
          roles,
          vendor_id,
        } = data

        if (access_token) {
          store.set('accessToken', encrypt(access_token))
        }

        store.set('profile', {
          areas,
          actions,
          email,
          id,
          ip,
          name,
          last_login,
          phone,
          regions,
          roles,
          vendor_id,
        })

        window.location.href = '/'
    },
  },
  getters: {
    user: state => state,
    distributor: state => {
      return {
        isDistributor: state.roles.some(r => r.group_role === 'Distributor'),
        vendor_id: state.vendor_id,
      }
    },
    menu: state => state.menu,
  },
}
